.modal-dialog.in-store-inventory-dialog {
  .modal-body {
    .store-locator-container {
      .btn-click-collect {
        border-color: #000;
        background: #000;
        border-radius: 25px;
      }
    }
  }
}

.modal-header.justify-content-end,
.modal-dialog.in-store-inventory-dialog {
  .modal-title {
    font-family: $main-font;
    font-size: 1.25rem;
  }
}
