header ~ #maincontent {
  .container.product-detail {
    .row.pdp-container {
      .prices-add-to-cart-actions {
        .cart-and-ipay {
          width: 100%;
          margin: 0 auto 15px;

          .btn-primary {
            @include font(700, 16px, 20px);

            background: #121212;
            border-color: #121212;
            border-radius: 26px;
            color: #fff;
            height: 50px;
            line-height: 17px;
            text-transform: uppercase;
            width: 100%;

            @include media-breakpoint-up(lg) {
              max-width: 280px;
            }
          }
        }

        .btn-checkout-container {
          .checkout-btn-add {
            @include font(700, 16px, 20px);

            color: #fff;
            border-color: #e26e6c;
            height: 50px;
            background: #e26e6c;
            border-radius: 26px;
            text-transform: uppercase;
            width: 100%;

            @include media-breakpoint-up(lg) {
              max-width: 280px;
            }
          }

          @include media-breakpoint-up(lg) {
            width: 100%;
            margin: 0 auto 15px;
          }
        }
      }
    }

    @import "./clickAndCollectPDP";
  }
}

.click-collect {
  margin-top: 30px;
  margin-bottom: -10px;

  @include respond-to-between(768px, 991px) {
    margin-top: 30px;
  }

  @include respond-to-max-width(768px) {
    margin-top: 0;
  }
}

.free-pick-up-span {
  color: #5b2e82;
  font-weight: 500;
  border: none;
  background: none;
  padding: 0;
}

.tooltip-clickandcollect {
  &.one,
  &.two,
  &.three {
    display: none;
    position: absolute;
    width: 330px;
    background-color: #dbdcda;
    color: #000;
    border-radius: 4px;
    padding: 8px 8px 8px 16px;
    z-index: 1;
    top: 63px;
    left: 112px;
    font-size: 14px;
    font-family: $main-font;

    p {
      text-align: left;
      margin: 0;
      font-weight: normal;
    }

    @include respond-to-max-width(991px) {
      left: 30px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 22px;
    margin-left: -8px;
    border-width: 10px 7px 0;
    transform: rotate(180deg);
    border-style: solid;
    border-color: #dbdcda transparent transparent;
  }
}

.available-locations-p {
  color: #000;
  font-family: $main-font;
  font-weight: 300;
}

.btn-get-in-store-inventory {
  border: none;
  background: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    .free-pick-up-span,
    .available-locations-p {
      opacity: 0.4;
    }
  }

  @include respond-to-max-width(768px) {
    margin-top: 40px;
    text-align: left;
  }
}
