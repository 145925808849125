.quick-view-dialog {
  .product-detail {
    .quick-view-info {
      .button-container {
        .btn-checkout-container {
          button {
            background-color: $secondary-color-1;
            border-color: $secondary-color-1;
            font-family: $main-font;
            font-weight: bold;
          }
        }
      }
    }
  }
}
