body {
  font-family: $main-font;
}

button,
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="password"],
input[type="text"] {
  box-shadow: none !important;
}

.home-email-signup {
  background-color: $secondary-color-1;

  .row .col-sm-12 {
    .newsletter {
      &-title {
        color: $text-color-footer;
        text-transform: uppercase;
      }

      &-disclaimer {
        color: $text-color-footer;
        text-align: left;

        @include respond-to-max-width($max-mobile-width) {
          text-align: center;
        }
      }
    }

    .input-group {
      width: 95%;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;

      .form-control {
        border-radius: 25px;
        margin-right: 0;
        width: 100%;
        border: 0;

        @include respond-to-min-width($max-tablet-width) {
          margin-right: 20px;
          padding: 20px;
        }
      }

      &-append > .btn {
        border-radius: 25px;
        background-color: $primary-color-1;
        color: $primary-color-2;
        text-transform: uppercase;
        width: auto;
        padding: 10px 50px;
        margin-top: 20px;
        border: 1px $social-links-color solid;

        @include respond-to-min-width($max-tablet-width) {
          padding-left: 20px;
          padding-right: 20px;
          border: none;
          font-size: 15px;
          font-weight: 700;
          letter-spacing: 0.5px;
          margin-top: 0;
        }
      }

      @include respond-to-min-width($max-tablet-width) {
        display: flex;
        flex-flow: inherit;
        align-items: center;
      }
    }
  }
}

.minicart {
  .minicart-quantity {
    background-color: $secondary-color-1;
    line-height: 1.5;
  }

  .popover-bottom {
    .minicart-footer {
      .btn-primary {
        font-weight: 700;
        background-color: $primary-color-2;
        border-color: $primary-color-2;
        border-radius: 25px;
        color: $primary-color-1;
        text-transform: uppercase;
        line-height: 2.3;
      }
    }
  }
}

.form-control {
  border: 1px solid $primary-color-2;
}

nav[role="navigation"] {
  .account-mobile {
    ul.dropdown-menu {
      li.dropdown-item:last-child a {
        @include respond-to-max-width($max-mobile-width) {
          color: $primary-color-2;
        }
      }
    }
  }

  .header {
    .navbar-header {
      .user {
        .popover {
          ul.nav {
            li.nav-item:last-child a {
              color: $primary-color-2;
            }
          }
        }
      }

      &.brand {
        .logo-home {
          img {
            width: 120px;

            @include respond-to-max-width($max-mobile-width) {
              width: 100%;
            }
          }
        }

        @include respond-to-max-width($max-mobile-width) {
          top: 43%;
        }
      }
    }
  }

  .search-mobile {
    .site-search {
      .fa-search {
        color: $primary-color-2;
      }
    }
  }

  .main-menu {
    border-top: 0.5px solid #979797;

    .container {
      @include respond-to-max-width($max-tablet-width) {
        margin-left: 0;
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      &.multilevel-dropdown {
        .close-menu {
          padding: 15px 35px 4px 18px;

          .back {
            img {
              width: 90%;
            }

            &.pull-left {
              img {
                height: 40px;
                width: 100%;
                margin-top: 5%;
              }
            }
          }
        }
      }
    }
  }

  .brand img {
    @include respond-to-max-width($max-mobile-width) {
      width: 100%;
    }
  }
}

.site-search {
  .fa-close,
  .fa-search,
  .reset-button {
    color: $primary-color-2;
  }

  input {
    border: 1px solid $icon-color;
  }
}

button {
  font-weight: 700;
  background-color: $primary-color-2;
  border-color: $primary-color-2;
  border-radius: 25px;
  color: $primary-color-1;
}

.modal-dialog.quick-view-dialog {
  .modal-content {
    .modal-body {
      .button-container {
        button {
          font-weight: 700;
          background-color: $primary-color-2;
          border-color: $primary-color-2;
          border-radius: 25px;
          color: $primary-color-1;
          text-transform: uppercase;
        }
      }
    }
  }
}

.page-designer-reference {
  .carousel-title {
    font-weight: 700;
    text-transform: uppercase;

    &.with-subtitle {
      color: $secondary-color-1;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .carousel-subtitle {
    color: $secondary-color-1;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }
}

.carousel {
  &.instagram-carousel {
    .carousel-control-next i,
    .carousel-control-prev i {
      margin-bottom: 15px;
    }
  }
}

.totals {
  .cart-button-promotion {
    .checkout-btn {
      font-weight: 700;
      background-color: $primary-color-2;
      border-color: $primary-color-2;
      border-radius: 25px;
      color: $primary-color-1;
      text-transform: uppercase;
    }
  }

  .coupons-and-promos {
    .coupon-price-adjustment {
      button.remove-coupon {
        span {
          color: black;
        }
      }
    }
  }
}

.btn-primary,
.email-verification-page .card .card-body a button {
  font-weight: 700;
  background-color: $primary-color-2;
  border-color: $primary-color-2;
  color: $primary-color-1;
  text-transform: uppercase;
}

.stamp {
  &-promotion-PRODUCT,
  &-is-customizable,
  &-is-launch {
    color: $product-promotion-stamp-color !important;
    background-color: none !important;
  }

  &-promotion-ORDER {
    color: $order-promotion-stamp-color !important;
    background-color: none !important;
  }
}

.product-stamp {
  display: none;

  &:first-child,
  &:nth-child(2) {
    display: block;
  }

  &:first-child {
    padding-left: 0;
    margin-left: 0;
  }

  @include respond-to-max-width(991px) {
    &:nth-child(2) {
      display: none;
    }
  }
}

.order {
  &-receipt-label {
    color: $secondary-color-1;
  }

  &-total-summary .shipping-discount p,
  &-discount p {
    color: $secondary-color-1;
  }
}

header ~ #maincontent {
  .container {
    .product {
      .product-tile {
        .tile-body .installments-container {
          color: #9a9a9a;
        }
      }
    }

    .wishlistItemCards {
      .card {
        &.product-info {
          .add-to-cart-wishList {
            .add-to-cart-text-button-container {
              .visit-product-detail {
                background-color: $primary-color-2;
                color: $primary-color-1;
                border-radius: 25px;
                text-transform: uppercase;
                text-align: center;

                &:hover {
                  background-color: $primary-color-2;
                }

                &:focus {
                  box-shadow: none;
                }
              }
            }

            .add-to-cart-btn {
              font-weight: 700;
              width: 280px !important;

              @include respond-to-max-width(769px) {
                margin-bottom: 15px;
              }
            }
          }
        }
      }
    }

    .multi-shipping {
      .card {
        .address-validation-btn-container {
          .address-validation-btn {
            text-transform: uppercase;
            border-radius: 25px;
          }
        }
      }
    }

    .card-body {
      &.shipping-content {
        .shipping-form {
          .shipping-address {
            .tab-content {
              .instore-pickup,
              .pickit-store-search {
                button {
                  text-transform: uppercase;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }

    &.product-detail {
      .prices-add-to-cart-actions {
        .btn-primary {
          background-color: $primary-color-2;
          border-color: $primary-color-2;
          color: $primary-color-1;
          text-transform: uppercase;
        }
      }

      .cart-and-ipay {
        .add-to-cart {
          background-color: $primary-color-2;
          border-color: $primary-color-2;
          border-radius: 25px;
          color: $primary-color-1;
          text-transform: uppercase;
        }
      }

      .saving-and-installments-container {
        color: $secondary-color-1;
      }

      .stamp-limited-product-sku {
        font-size: 17px;
        max-width: 70%;
      }
    }

    &.login-page {
      .card {
        .login-form-nav {
          .tab-pane {
            form button,
            .registration button {
              font-weight: 700;
              background-color: $primary-color-2;
              border-color: $primary-color-2;
              border-radius: 25px;
              color: $primary-color-1;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}

#footballTeam,
label[for="footballTeam"] {
  visibility: hidden;
}

.account-payment-methods {
  .card-body {
    .payment-form {
      button,
      a {
        color: $secondary-color-2;
        background-color: $primary-color-2;
        border: none;
        border-radius: 25px;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 20px;

        &:hover {
          background-color: $primary-color-2;
        }

        &:focus {
          box-shadow: none;
        }

        &.btn-save {
          font-weight: 700;
          border-color: $primary-color-2;
          border-radius: 25px;
          color: $primary-color-1;
          text-transform: uppercase;
          box-shadow: none !important;

          &:hover {
            font-weight: 700;
            border-color: $primary-color-1;
            border-radius: 25px;
            color: $primary-color-1;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.account-payment-methods,
.payments,
.address-book,
.addresses {
  .add-new {
    a {
      padding: 15px 20px;
      border: none;
      outline: 0;
      height: 50px;
      display: block;
      margin: 0 auto;
      font-weight: 700;
      background-color: $primary-color-2;
      border-color: $primary-color-2;
      border-radius: 25px;
      color: $primary-color-1;
      text-transform: uppercase;
      font-family: $main-font;

      &:hover {
        background-color: $primary-color-2;
      }

      &:focus {
        background: black !important;
        box-shadow: none !important;
      }
    }
  }
}

.store-locator-container {
  .select-store {
    opacity: 1;
    height: 50px;
    width: 100%;
    font-family: $main-font;
    font-weight: 700;
    background-color: $primary-color-2;
    border-color: $primary-color-2;
    border-radius: 25px;
    color: $primary-color-1;
    text-transform: uppercase;

    &:active {
      background: $primary-color-2;
    }

    &:focus {
      outline: none;
    }

    &.btn-primary {
      @include respond-to-min-width(544px) {
        margin-top: 1rem;
      }
    }
  }
}

div[data-action="Account-SetNewPassword"],
div[data-action="Account-SaveNewPassword"] {
  #maincontent {
    .container {
      .card {
        .card-body {
          form > .row {
            a,
            button {
              border-radius: 25px;
              text-transform: uppercase;
              border: 2px solid $primary-color-2;
            }

            a {
              background-color: $primary-color-1;
              color: $primary-color-2;
            }

            button {
              background-color: $primary-color-2;
              color: $primary-color-1;
            }
          }
        }
      }
    }
  }
}

.link-redirect {
  width: 70%;
  height: auto;
  margin-left: 10%;
  margin-top: 10%;
}

footer {
  &__logo {
    position: relative;
    margin-left: 20%;
    margin-top: 13%;

    @include respond-to-max-width($max-tablet-width) {
      margin-left: 5%;
      margin-top: 0;
    }
  }

  .content {
    font-size: 13px;
  }

  .back-to-top {
    @include respond-to-max-width($max-mobile-width) {
      margin-left: 2%;
    }

    .fa-arrow-up {
      color: $bg-color-footer;
    }
  }

  .fa-inverse {
    color: $primary-color-2;
  }
}

.header {
  &__nav-redirect {
    &-moov {
      display: none;
    }

    &-dexter {
      padding: 0 22px;
      position: relative;
      width: fit-content;
      border-left: 2px solid #dbdcda;
      border-right: 2px solid #dbdcda;
      margin: 0 auto;
    }
  }

  &-banner {
    color: $primary-color-2;

    .close-button {
      .close {
        color: $primary-color-2;
      }
    }
  }
}

.footer {
  &__logo {
    position: relative;
    margin-left: 20%;
    margin-top: 13%;

    @include respond-to-max-width($max-tablet-width) {
      margin-left: 5%;
      margin-top: 0;
    }
  }

  &__link-redirect-mobile {
    width: 100%;
    margin-left: -6px;
  }
}

.my-orders__tabs {
  li {
    &.nav-item {
      a {
        &:not(.btn-primary):not(.btn-outline-primary).my-orders__tab-link {
          &.active {
            border-bottom: 0.188em solid $secondary-color-1;
          }
        }
      }
    }
  }
}

@include respond-to-max-width(543.98px) {
  #home {
    #recommendations {
      .carousel.indicators-xs {
        padding-bottom: 0;
      }
    }
  }
}

@include respond-to-max-width($max-mobile-width) {
  .link-redirect {
    display: none;
  }

  .header-banner {
    .content {
      font-size: 18px;
    }
  }
}
