div .home-email-signup {
  background-color: #e26e6c;

  .row {
    .col {
      &-sm-12 {
        .newsletter {
          &-title {
            color: $text-color-footer;
            text-transform: uppercase;
          }

          &-disclaimer {
            color: $text-color-footer;
            text-align: center;

            @include respond-to-max-width($max-mobile-width) {
              text-align: center;
            }
          }
        }
      }

      &-12 {
        font-size: 14px;

        .input-group {
          &-contact {
            width: 95%;
            margin-bottom: 10px;

            .form-control {
              border-radius: 25px;
              height: 40px;
              border: 0;
              color: $black-text;

              &::placeholder {
                color: #000;
                font-size: 16px;
                font-weight: 400;

                @include respond-to-max-width($max-mobile-width) {
                  font-size: 11px;
                }
              }

              &-custom {
                border: none;
                border-bottom: 1px solid #000;
                border-color: #000;
                border-radius: 0;
                -webkit-box-shadow: 0 0 0 1000px #e26e6c inset;
                box-shadow: 0 0 0 1000px #e26e6c inset;
                background: none;

                &::placeholder {
                  color: #000;
                }

                &:focus {
                  border-color: #000;
                }

                &:focus,
                :not(:placeholder-shown) {
                  + .placeholder-label {
                    transform: translateY(-30px);
                    color: #000;
                  }
                }
              }
            }

            .label_mail {
              &::before {
                content: "*";
                color: #c00;
                margin-right: 3px;
              }
            }

            @include respond-to-max-width($max-mobile-width) {
              width: 100%;
            }
          }

          &-right {
            margin-top: 0;
          }
        }

        .invalid {
          &__email,
          &__phone {
            &-home {
              color: white;
              font-size: 13px;
              width: 100%;
            }
          }

          &__email {
            &_home {
              margin-left: 0;

              @include media-breakpoint-up(md) {
                margin-left: 0;
                padding-left: 1.75rem;
                width: 100%;
              }
            }
          }

          &__phone {
            &_home {
              margin: auto auto 10px 0;
            }
          }
        }

        .checkbox-contact {
          display: flex;
          margin-left: 27px;

          .form-group {
            .wp-check {
              margin-right: 40px;
            }

            .custom-control {
              &-label {
                padding-top: 4px;

                &::before {
                  border: 1px solid #000;
                  background: #f9f9f9;
                  background: linear-gradient(#f9f9f9, #ccc);
                  border-radius: 0.1875rem;
                }

                &::after {
                  cursor: pointer;
                  background-color: white;
                  border: 1px solid black;
                  border-radius: 3px;
                }
              }

              &-input:checked ~ .custom-control-label::after {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
                background-color: $primary-color-2;
              }
            }
          }
        }
      }
    }
  }

  .check_contact {
    font-size: 14px;
    text-align: left;

    a {
      font-weight: 500;
      text-decoration: underline;
    }

    @include media-breakpoint-up(sm) {
      text-align: center;
    }
  }

  .input-group-append {
    width: 100%;
    padding: 0 6px;

    > .btn {
      background-color: #fff;
      height: 50px;
      text-transform: uppercase;
      border-radius: 25px;
      color: #000;
      border: none;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0.5px;
      width: 385px;
      margin: 20px auto;
      padding: 10px 50px;

      @include respond-to-max-width($max-mobile-width) {
        font-size: 14px;
        width: 100%;
        font-weight: 500;
        letter-spacing: 0;
      }
    }
  }

  @include respond-to-max-width($max-mobile-width) {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
  }
}

.experience-commerce_assets-cg_newsletter .placeholder-label {
  color: #000;
}

.page-designer-reference #NewsLetter-btn.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #fff;
  color: #000;
  border: none;
}
