$bg-color: #000;

#mainContainer {
  .header {
    &__main__nav{
      background-color: $bg-color;
    }
  }
}

.header.container {
  max-width: 100% !important;
  background-color: $bg-color; //this color affecting only the cart page
}

//RESPONSIVITY FOR HEADER MOBILE RESOLUTION LOWER THAN 768PX

.col-sm-3 .brand {
  left: 0 !important;
  margin-left: 0 !important;
}

.menu-mobile{
  .navbar-toggler{
    font-size: 1.6em;
    width: auto;
    color: white;
  }
}
//CSS TO ADAPT MOBILE AND TABLET HEADERS WITH THE NEW MINICART LAYOUT
.col-sm-3.hidden-xs-down{
  .navbar-header.brand{
    .logo-home{
      @media (max-width: 769px){
        display: none !important;
      }
    }
  }
}

//CSS FOR HEADER AT CART-PAGE
[data-action="Cart-Show"] {
  .header{
    &__right{
      .right-menu{
        padding-bottom: $gutter - ($gutter / 4);
      }
    }

    .header__logo{
      min-width: 115px;
      padding-top: $gutter - ($gutter / 4);
    }
  }
}
// @import "nav-header";
